/*  Globals / Abstracts */
@import 
"global/vars",
"global/mixins"
;

/* Vendors */
// @import "../../node_modules/tachyons-sass/tachyons"; //https://tachyons.io/
@import "../../assets/library/tachyons-sass-main/tachyons"; // https://github.com/tachyons-css/tachyons-sass merge on 5 Oct 2021 github.com


// @import "../../node_modules/flexboxgrid/css/flexboxgrid"; //http://flexboxgrid.com/
//@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css"; //http://fancyapps.com/fancybox/3/
// @import "../../node_modules/material-scrolltop/dist/material-scrolltop"; //https://github.com/bartholomej/material-scrolltop
@import "../../css/font-awesome.min.css";
@import "../../css/ionicons.min.css";
@import "../../css/bootstrap.min.css";



// Vendor (Modified)
@import 
// "vendor/v-flexboxgrid",
"vendor/v-thachyons",
"vendor/v-material-scrolltop"
// @import "vendor/v-fancybox";
;


/* Base */
@import 
"base/fonts",
"base/theme"
// @import "base/wp";
;

@import 
"theme/style",
"theme/custom"
;

// u- Utils 
@import 
"utils/u-ratio",
"utils/--dev"
;

// Partials
@import 
"partials/body",
"partials/header",
"partials/main",
"partials/footer"
;


// State / State JS
@import 
"state/s-general"
;


// Components
@import 
"components/c-map",
"components/c-columns-gradient",
"components/c-slider-home"
;

// Elements 
@import 
"elements/e-elements"
;




#body.body--page-index  li.is-index > a ,
#body.body--page-fabricacion  li.is-fabricacion > a ,
#body.body--page-produccion  li.is-produccion > a ,
#body.body--page-productos  li.is-productos > a ,
#body.body--page-noticias  li.is-noticias > a ,
#body.body--page-single-noticia  li.is-noticias > a ,
#body.body--page-contacto  li.is-contacto > a ,


#body li.is-lang .item-esp ,

#header li.current a

{
  color: #5fbd74;
  font-weight:bold !important;
  
}

#header.scrolling-menu {
  .logo-image {
    max-width:180px;
  }
}

@include mobile {
  .section-pretitle.default-left {
    margin-left: 0;
  }
}

.section-bg-2 {
  // margin-top:4em;
  // padding-top:70%;
}

.e-color-black--mobile {
  @include mobile {
  color:#111 !important;
  }

}

