// e-style-number-theme;
.e-style-number-theme {
  margin:0;
  padding:.5em;
  counter-reset: myOrderedListItemsCounter;
  li {
    margin:0 0 1.6em 0;
    list-style: none;
    margin-left: 2em;
    position: relative;

    &:before {
      counter-increment: myOrderedListItemsCounter;
      content: counter(myOrderedListItemsCounter); 
      // content: counter(myOrderedListItemsCounter,decimal-leading-zero); 
      color: red;
      display: inline-block; 
      width: 2em;
      height:2em;
      line-height: 1.7em;
      margin-left: -1.8em;
      margin-right: .5em;
      background-color:transparent;
      text-align: center;
      color:#FFF;
      border-radius:50%;
      font-size: .7em;
      position:absolute;
      left:-1em;
      font-weight:bold;
      border:2px solid $branding_white;
    }
  }
}