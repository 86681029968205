/**
Theme Name: Organik
*/
/**
 * Table of Contents
 *
 * 1. Padding, Margin, Font-size
 * 2. Custom CSS
 */


/** {
  outline: 1px solid yellow !important;
  }*/
.BeautifulFriday {
  // font-family: BeautifulFriday;
  font-weight:bold;
  font-family: 'Amatic SC', cursive;
}

#body.body.body--page-produccion .section.bg-light {
  background-color:#FFF !important;
}


  .logo-image {
    padding-top:20px;
    max-width: 270px;
  }
  .logo-image--mobile {
    max-width: 230px;
    padding-top:0;
  }

  header.header-mobile {
    padding: 10px 0;
    /* width: 100%; */
  }


  #header .is-lang a {
    padding-right:8px;
    padding-left:8px
  }


  @media screen and (min-width: 1170px) {

    .container--max  {

      width: 1500px;

    }
  }
  @media screen and (max-width: 1200px) {
    
    .container--max  {
      width:100%;
    }
  }
 

  .e-list-dotted {
    list-style: inside;
  }
  .e-list-dotted li {
    margin-bottom: .8em;
    line-height:1.5em;
  }

  .contact-form {
    margin-bottom: 8em;

    max-width:900px;
    margin-right:auto;
    margin-left:auto;

    input[type="text"] ,
    input[type="email"],
    textarea 
    {
      border-top:3px solid #444;
    }
    input[type="submit"] {
      padding:15px 60px;
      font-size: 1.2em;
    }
  }

/*--------------------------------------------------------------
1. Padding, Margin, Font-size
--------------------------------------------------------------*/
.gv-font {
  font-family: "Great Vibes" !important;
}

/* Font style */
.fsi {
  font-style: italic !important;
}

.fsn {
  font-style: normal !important;
}

/* Font weight */
.fw-lightest {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fz-5 {
  font-size: 50px;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-13 {
  font-size: 13px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-15 {
  font-size: 15px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-17 {
  font-size: 17px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-21 {
  font-size: 21px !important;
}

.fz-22 {
  font-size: 22px !important;
}

.fz-24 {
  font-size: 24px !important;
}

.fz-25 {
  font-size: 25px !important;
}

.fz-26 {
  font-size: 26px !important;
}

.fz-27 {
  font-size: 27px !important;
}

.fz-28 {
  font-size: 28px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-32 {
  font-size: 32px !important;
}

.fz-34 {
  font-size: 34px !important;
}

.fz-35 {
  font-size: 35px !important;
}

.fz-36 {
  font-size: 36px !important;
}

.fz-38 {
  font-size: 38px !important;
}

.fz-40 {
  font-size: 40px !important;
}

.fz-42 {
  font-size: 42px !important;
}

.fz-44 {
  font-size: 44px !important;
}

.fz-46 {
  font-size: 46px !important;
}

.fz-48 {
  font-size: 48px !important;
}

.fz-50 {
  font-size: 50px !important;
}

.fz-52 {
  font-size: 52px !important;
}

.fz-54 {
  font-size: 54px !important;
}

.fz-56 {
  font-size: 56px !important;
}

.fz-58 {
  font-size: 58px !important;
}

.fz-59 {
  font-size: 59px !important;
}

.fz-60 {
  font-size: 60px !important;
  line-height: 80px !important;
}

.fz-62 {
  font-size: 62px !important;
}

.fz-64 {
  font-size: 64px !important;
}

.fz-65 {
  font-size: 65px !important;
}

.fz-66 {
  font-size: 66px !important;
}

.fz-68 {
  font-size: 68px !important;
}

.fz-72 {
  font-size: 72px !important;
}

.fz-73 {
  font-size: 73px !important;
}

.fz-76 {
  font-size: 76px !important;
}

.fz-82 {
  font-size: 82px !important;
}

.fz-85 {
  font-size: 85px !important;
}

.fz-86 {
  font-size: 86px !important;
}

.fz-88 {
  font-size: 88px !important;
}

.fz-90 {
  font-size: 90px !important;
}

.fz-100 {
  font-size: 100px !important;
}

.lh-16 {
  line-height: 16px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-30 {
  line-height: 30px;
}

.lh-32 {
  line-height: 32px;
}

.lh-35 {
  line-height: 35px;
}

.lh-38 {
  line-height: 38px;
}

.lh-40 {
  line-height: 40px;
}

.lh-42 {
  line-height: 42px;
}

.lh-45 {
  line-height: 45px;
}

.lh-48 {
  line-height: 48px;
}

.lh-49 {
  line-height: 49px;
}

.lh-55 {
  line-height: 55px;
}

.lh-60 {
  line-height: 60px;
}

.lh-62 {
  line-height: 62px;
}

.lh-66 {
  line-height: 66px;
}

.lh-68 {
  line-height: 68px;
}

.lh-70 {
  line-height: 70px;
}

.lh-73 {
  line-height: 73px;
}

.lh-76 {
  line-height: 76px;
}

.lh-80 {
  line-height: 80px;
}

.lh-82 {
  line-height: 82px;
}

.lh-86 {
  line-height: 86px;
}

.lh-90 {
  line-height: 90px;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.ls-4 {
  letter-spacing: 4px;
}

.ls-5 {
  letter-spacing: 5px;
}

.ls-6 {
  letter-spacing: 6px;
}

.ls-10 {
  letter-spacing: 10px;
}

.ls-12 {
  letter-spacing: 12px;
}

.ls-18 {
  letter-spacing: 18px;
}

/* Padding */
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.p-3 {
  padding: 30px !important;
}

.p-4 {
  padding: 40px !important;
}

.p-5 {
  padding: 50px !important;
}

.p-6 {
  padding: 60px !important;
}

.p-7 {
  padding: 70px !important;
}

.p-8 {
  padding: 80px !important;
}

.p-9 {
  padding: 90px !important;
}

.p-10 {
  padding: 100px !important;
}

.p-11 {
  padding: 110px !important;
}

.p-12 {
  padding: 120px !important;
}

.p-13 {
  padding: 130px !important;
}

.p-14 {
  padding: 140px !important;
}

.p-15 {
  padding: 150px !important;
}

.p-16 {
  padding: 160px !important;
}

.p-17 {
  padding: 170px !important;
}

.p-18 {
  padding: 180px !important;
}

.p-19 {
  padding: 190px !important;
}

.p-20 {
  padding: 200px !important;
}

/* Padding top */
.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pt-6 {
  padding-top: 60px !important;
}

.pt-7 {
  padding-top: 70px !important;
}

.pt-8 {
  padding-top: 80px !important;
}

.pt-9 {
  padding-top: 90px !important;
}

.pt-10 {
  padding-top: 100px !important;
}

.pt-11 {
  padding-top: 110px !important;
}

.pt-12 {
  padding-top: 120px !important;
}

.pt-13 {
  padding-top: 130px !important;
}

.pt-14 {
  padding-top: 140px !important;
}

.pt-15 {
  padding-top: 150px !important;
}

.pt-16 {
  padding-top: 160px !important;
}

.pt-17 {
  padding-top: 170px !important;
}

.pt-18 {
  padding-top: 180px !important;
}

.pt-19 {
  padding-top: 190px !important;
}

.pt-20 {
  padding-top: 200px !important;
}

.pt-22 {
  padding-top: 220px !important;
}

.pt-26 {
  padding-top: 260px !important;
}

.pt-28 {
  padding-top: 280px !important;
}

.pt-33 {
  padding-top: 330px !important;
}

/* Padding right */
.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.pr-6 {
  padding-right: 60px !important;
}

.pr-7 {
  padding-right: 70px !important;
}

.pr-8 {
  padding-right: 80px !important;
}

.pr-9 {
  padding-right: 90px !important;
}

.pr-10 {
  padding-right: 100px !important;
}

.pr-11 {
  padding-right: 110px !important;
}

.pr-12 {
  padding-right: 120px !important;
}

.pr-13 {
  padding-right: 130px !important;
}

.pr-14 {
  padding-right: 140px !important;
}

.pr-15 {
  padding-right: 150px !important;
}

.pr-16 {
  padding-right: 160px !important;
}

.pr-17 {
  padding-right: 170px !important;
}

.pr-18 {
  padding-right: 180px !important;
}

.pr-19 {
  padding-right: 190px !important;
}

.pr-20 {
  padding-right: 200px !important;
}

.pr-25 {
  padding-right: 250px !important;
}

.pr-6p {
  padding-right: 6%;
}

.pr-13p {
  padding-right: 13%;
}

/* Padding bottom */
.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pb-6 {
  padding-bottom: 60px !important;
}

.pb-7 {
  padding-bottom: 70px !important;
}

.pb-8 {

  padding-bottom: 80px !important;
}

.pb-9 {
  padding-bottom: 90px !important;
}

.pb-10 {
  padding-bottom: 100px !important;
}

.pb-11 {
  padding-bottom: 110px !important;
}

.pb-12 {
  padding-bottom: 120px !important;
}

.pb-13 {
  padding-bottom: 130px !important;
}

.pb-14 {
  padding-bottom: 140px !important;
}

.pb-15 {
  padding-bottom: 150px !important;
}

.pb-16 {
  padding-bottom: 160px !important;
}

.pb-17 {
  padding-bottom: 170px !important;
}

.pb-18 {
  padding-bottom: 180px !important;
}

.pb-19 {
  padding-bottom: 190px !important;
}

.pb-20 {
  padding-bottom: 200px !important;
}

.pb-22 {
  padding-bottom: 220px !important;
}

.pb-25 {
  padding-bottom: 250px !important;
}

.pb-26 {
  padding-bottom: 260px !important;
}

.pb-36 {
  padding-bottom: 360px !important;
}

.pb-45 {
  padding-bottom: 450px !important;
}

/* Padding left */
.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.pl-6 {
  padding-left: 60px !important;
}

.pl-7 {
  padding-left: 70px !important;
}

.pl-8 {
  padding-left: 80px !important;
}

.pl-9 {
  padding-left: 90px !important;
}

.pl-10 {
  padding-left: 100px !important;
}

.pl-11 {
  padding-left: 110px !important;
}

.pl-12 {
  padding-left: 120px !important;
}

.pl-13 {
  padding-left: 130px !important;
}

.pl-14 {
  padding-left: 140px !important;
}

.pl-15 {
  padding-left: 150px !important;
}

.pl-16 {
  padding-left: 160px !important;
}

.pl-17 {
  padding-left: 170px !important;
}

.pl-18 {
  padding-left: 180px !important;
}

.pl-19 {
  padding-left: 190px !important;
}

.pl-20 {
  padding-left: 200px !important;
}

.pl-25 {
  padding-left: 250px !important;
}

.pl-6p {
  padding-left: 6%;
}

.pl-13p {
  padding-left: 13%;
}

/* Margin */
.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mltb-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 10px !important;
}

.m-2 {
  margin: 20px !important;
}

.m-3 {
  margin: 30px !important;
}

.m-4 {
  margin: 40px !important;
}

.m-5 {
  margin: 50px !important;
}

.m-6 {
  margin: 60px !important;
}

.m-7 {
  margin: 70px !important;
}

.m-8 {
  margin: 80px !important;
}

.m-9 {
  margin: 90px !important;
}

.m-10 {
  margin: 100px !important;
}

.m-11 {
  margin: 110px !important;
}

.m-12 {
  margin: 120px !important;
}

.m-13 {
  margin: 130px !important;
}

.m-14 {
  margin: 140px !important;
}

.m-15 {
  margin: 150px !important;
}

.m-16 {
  margin: 160px !important;
}

.m-17 {
  margin: 170px !important;
}

.m-18 {
  margin: 180px !important;
}

.m-19 {
  margin: 190px !important;
}

.m-20 {
  margin: 200px !important;
}

/* Margin top */
.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-6 {
  margin-top: 60px !important;
}

.mt-7 {
  margin-top: 70px !important;
}

.mt-8 {
  margin-top: 80px !important;
}

.mt-9 {
  margin-top: 90px !important;
}

.mt-10 {
  margin-top: 100px !important;
}

.mt-11 {
  margin-top: 110px !important;
}

.mt-12 {
  margin-top: 120px !important;
}

.mt-13 {
  margin-top: 130px !important;
}

.mt-14 {
  margin-top: 140px !important;
}

.mt-15 {
  margin-top: 150px !important;
}

.mt-16 {
  margin-top: 160px !important;
}

.mt-17 {
  margin-top: 170px !important;
}

.mt-18 {
  margin-top: 180px !important;
}

.mt-19 {
  margin-top: 190px !important;
}

.mt-20 {
  margin-top: 200px !important;
}

.mt-23 {
  margin-top: 230px !important;
}

.mt-50 {
  margin-top: 500px !important;
}

/* Margin right */
.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.mr-6 {
  margin-right: 60px !important;
}

.mr-7 {
  margin-right: 70px !important;
}

.mr-8 {
  margin-right: 80px !important;
}

.mr-9 {
  margin-right: 90px !important;
}

.mr-10 {
  margin-right: 100px !important;
}

.mr-11 {
  margin-right: 110px !important;
}

.mr-12 {
  margin-right: 120px !important;
}

.mr-13 {
  margin-right: 130px !important;
}

.mr-14 {
  margin-right: 140px !important;
}

.mr-15 {
  margin-right: 150px !important;
}

.mr-16 {
  margin-right: 160px !important;
}

.mr-17 {
  margin-right: 170px !important;
}

.mr-18 {
  margin-right: 180px !important;
}

.mr-19 {
  margin-right: 190px !important;
}

.mr-20 {
  margin-right: 200px !important;
}

/* Margin bottom */
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mb-6 {
  margin-bottom: 60px !important;
}

.mb-7 {
  margin-bottom: 70px !important;
}

.mb-8 {
  margin-bottom: 80px !important;
}

.mb-9 {
  margin-bottom: 90px !important;
}

.mb-10 {
  margin-bottom: 100px !important;
}

.mb-11 {
  margin-bottom: 110px !important;
}

.mb-12 {
  margin-bottom: 120px !important;
}

.mb-13 {
  margin-bottom: 130px !important;
}

.mb-14 {
  margin-bottom: 140px !important;
}

.mb-15 {
  margin-bottom: 150px !important;
}

.mb-16 {
  margin-bottom: 160px !important;
}

.mb-17 {
  margin-bottom: 170px !important;
}

.mb-18 {
  margin-bottom: 180px !important;
}

.mb-19 {
  margin-bottom: 190px !important;
}

.mb-20 {
  margin-bottom: 200px !important;
}

.mb-24 {
  margin-bottom: 240px !important;
}

/* Margin left */
.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.ml-6 {
  margin-left: 60px !important;
}

.ml-7 {
  margin-left: 70px !important;
}

.ml-8 {
  margin-left: 80px !important;
}

.ml-9 {
  margin-left: 90px !important;
}

.ml-10 {
  margin-left: 100px !important;
}

.ml-11 {
  margin-left: 110px !important;
}

.ml-12 {
  margin-left: 120px !important;
}

.ml-13 {
  margin-left: 130px !important;
}

.ml-14 {
  margin-left: 140px !important;
}

.ml-15 {
  margin-left: 150px !important;
}

.ml-16 {
  margin-left: 160px !important;
}

.ml-17 {
  margin-left: 170px !important;
}

.ml-18 {
  margin-left: 180px !important;
}

.ml-19 {
  margin-left: 190px !important;
}

.ml-20 {
  margin-left: 200px !important;
}

/* Margin top negative */
.mtn-0 {
  margin-top: 0px !important;
}

.mtn-1 {
  margin-top: -10px !important;
}

.mtn-2 {
  margin-top: -20px !important;
}

.mtn-3 {
  margin-top: -30px !important;
}

.mtn-4 {
  margin-top: -40px !important;
}

.mtn-5 {
  margin-top: -50px !important;
}

.mtn-6 {
  margin-top: -60px !important;
}

.mtn-7 {
  margin-top: -70px !important;
}

.mtn-8 {
  margin-top: -80px !important;
}

.mtn-9 {
  margin-top: -90px !important;
}

.mtn-10 {
  margin-top: -100px !important;
}

.mtn-11 {
  margin-top: -110px !important;
}

.mtn-12 {
  margin-top: -120px !important;
}

.mtn-13 {
  margin-top: -130px !important;
}

.mtn-14 {
  margin-top: -140px !important;
}

.mtn-15 {
  margin-top: -150px !important;
}

.mtn-16 {
  margin-top: -160px !important;
}

.mtn-17 {
  margin-top: -170px !important;
}

.mtn-18 {
  margin-top: -180px !important;
}

.mtn-19 {
  margin-top: -190px !important;
}

.mtn-20 {
  margin-top: -200px !important;
}

.mtn-22 {
  margin-top: -220px !important;
}

.mtn-27 {
  margin-top: -270px !important;
}

.mtn-32 {
  margin-top: -320px !important;
}

.mtn-35 {
  margin-top: -350px !important;
}

/* Margin right negative */
.mrn-0 {
  margin-right: 0px !important;
}

.mrn-1 {
  margin-right: -10px !important;
}

.mrn-2 {
  margin-right: -20px !important;
}

.mrn-3 {
  margin-right: -30px !important;
}

.mrn-4 {
  margin-right: -40px !important;
}

.mrn-5 {
  margin-right: -50px !important;
}

.mrn-6 {
  margin-right: -60px !important;
}

.mrn-7 {
  margin-right: -70px !important;
}

.mrn-8 {
  margin-right: -80px !important;
}

.mrn-9 {
  margin-right: -90px !important;
}

.mrn-10 {
  margin-right: -100px !important;
}



.mrn-11 {
  margin-right: -110px !important;
}

.mrn-12 {
  margin-right: -120px !important;
}

.mrn-13 {
  margin-right: -130px !important;
}

.mrn-14 {
  margin-right: -140px !important;
}

.mrn-15 {
  margin-right: -150px !important;
}

.mrn-16 {
  margin-right: -160px !important;
}

.mrn-17 {
  margin-right: -170px !important;
}

.mrn-18 {
  margin-right: -180px !important;
}

.mrn-19 {
  margin-right: -190px !important;
}

.mrn-20 {
  margin-right: -200px !important;
}

/* Margin bottom negative */
.mbn-0 {
  margin-bottom: 0px !important;
}

.mbn-1 {
  margin-bottom: -10px !important;
}

.mbn-2 {
  margin-bottom: -20px !important;
}

.mbn-3 {
  margin-bottom: -30px !important;
}

.mbn-4 {
  margin-bottom: -40px !important;
}

.mbn-5 {
  margin-bottom: -50px !important;
}

.mbn-6 {
  margin-bottom: -60px !important;
}

.mbn-7 {
  margin-bottom: -70px !important;
}

.mbn-8 {
  margin-bottom: -80px !important;
}

.mbn-9 {
  margin-bottom: -90px !important;
}

.mbn-10 {
  margin-bottom: -100px !important;
}

.mbn-11 {
  margin-bottom: -110px !important;
}

.mbn-12 {
  margin-bottom: -120px !important;
}

.mbn-13 {
  margin-bottom: -130px !important;
}

.mbn-14 {
  margin-bottom: -140px !important;
}

.mbn-15 {
  margin-bottom: -150px !important;
}

.mbn-16 {
  margin-bottom: -160px !important;
}

.mbn-17 {
  margin-bottom: -170px !important;
}

.mbn-18 {
  margin-bottom: -180px !important;
}

.mbn-19 {
  margin-bottom: -190px !important;
}

.mbn-20 {
  margin-bottom: -200px !important;
}

/* Margin left negative */
.mln-0 {
  margin-left: 0px !important;
}

.mln-1 {
  margin-left: -10px !important;
}

.mln-2 {
  margin-left: -20px !important;
}

.mln-3 {
  margin-left: -30px !important;
}

.mln-4 {
  margin-left: -40px !important;
}

.mln-5 {
  margin-left: -50px !important;
}

.mln-6 {
  margin-left: -60px !important;
}

.mln-7 {
  margin-left: -70px !important;
}

.mln-8 {
  margin-left: -80px !important;
}

.mln-9 {
  margin-left: -90px !important;
}

.mln-10 {
  margin-left: -100px !important;
}

.mln-11 {
  margin-left: -110px !important;
}

.mln-12 {
  margin-left: -120px !important;
}

.mln-13 {
  margin-left: -130px !important;
}

.mln-14 {
  margin-left: -140px !important;
}

.mln-15 {
  margin-left: -150px !important;
}

.mln-16 {
  margin-left: -160px !important;
}

.mln-17 {
  margin-left: -170px !important;
}

.mln-18 {
  margin-left: -180px !important;
}

.mln-19 {
  margin-left: -190px !important;
}

.mln-20 {
  margin-left: -200px !important;
}

.mt-half {
  margin-top: 5px !important;
}

.mb-half {
  margin-bottom: 5px !important;
}

.z10 {
  z-index: 10;
}

.text-left {
  text-align: left;
}

.white {
  color: #fff !important;
}

.black {
  color: #000 !important;
}

.color {
  color: #5fbd74 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-dark {
  background-color: #333 !important;
}

.bg-light {
  background-color: #f5f3f0 !important;
}

@media (max-width: 768px) {
	.text-center-sm {
		text-align: center;
	}
	.text-left-sm {
		text-align: left;
	}
}
@media (max-width: 414px) {
	.pt-17, .pt-12 {
		padding-top: 40px !important;
	}
	.mtn-10 {
   margin-top: 0px !important; 
 }
 .mt-18 {
  margin-top: 40px !important;
}
.slick-dots {
  display: none !important;
}
}