// e-list-dots
.e-list-dots {
  li {
    a {
      position:relative;
      &::after {
        content:("·");
        display:block;
        position:absolute;
        top:0;
        right:0;
      }
    }
    &:last-child {
      a {
        &::after {
          display:none;
        }
      }
    }
  }
}