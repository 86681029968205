.c-columns-gradient {
  @include reset_list;
  background: rgb(54,173,45);
  background: linear-gradient(90deg, rgba(54,173,45,1) 0%, rgba(188,149,92,1) 100%);
  color:#FFF;
  .c-columns-gradient__container  {
    max-width:1500px;
    display:block;
    margin:0 auto;

    &-row {
      display:flex;
      flex-wrap:wrap;
    }
  }
  .c-columns-gradient__item {
    flex:1;
    padding:60px 40px;
    position:relative;
    @media screen and (max-width: 900px) {
      flex:initial;
      width:100%;
    }
    @include mobile {
       padding:20px 40px;
    }
    dt {
      font-size:1.8em;
      margin-bottom: .25em;
    }
    dd {
      font-size:1.08em;
    }
    &::before {
      content:("");
      position:absolute;
      height:70%;
      width:1px;
      background-color:#FFF;
      right:20px;
      top:15%;
      @media screen and (max-width: 900px) {
        flex:initial;
        width:100%;
        width:90%;
        top:initial;
        bottom:0%;
        height:1px;
        right:5%;
      }
    }
    &:last-child {
      border:0;
      &::before {
        display:none;
      }
    }
  }
  @include mobile {
    font-size:.8em;
  }
}