.c-map {
  iframe {
    outline:0;
  }
	&__area {
		box-sizing: border-box;
		margin:0;
		height:100%;
		display: flex;
		height:50vh;
    height:600px;
		@include mobile-theme {
			height:40vh;
		}
		abbr {
			margin:auto;
		}
	}
	&__footer {
		address {
			font-style: normal;
			margin:1em 0;
			font-weight: $font-light-theme;
			p {
				margin:0;
				color:$branding_black;
			}
			a {
				text-decoration:none;
				color:$branding_black;
			}
		}
	}
}