// E-CLEAR
.e-clear  {
  clear:both;
  &:after {
    content: "";
    display: block; 
    clear: both;
  }
}
@include mobile {
  .e-clear-mobile {
    clear:both;
  }
}