/* E-BACKGROUND */
.e-no-background {
  background:none !important;
}

.e-background-branding-green {
  background-color: $branding_green;
}
.e-background-branding-bronw {
  background-color: $branding_brown;
}

.e-background-branding-black-3 {
  background-color:$branding_black-3;
}
.e-background-branding-blue {
  background-color: $branding_blue;
}

.e-background-branding-white {
  background-color: $branding_white;
}

.e-background-white {
  background-color: #FFF;
}

.e-background-branding-blue-2 {
  background-color: $branding_blue_2;
}

.e-background-branding-yellow {
  background-color: $branding_yellow;
}

.e-background-branding-lilac {
  background-color: $branding_lilac;
}

.e-background-c-blue {
  background-color:$branding_blue2;
}