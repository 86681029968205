// E-MESSAGE 
.e-message {
  text-align: center;
  font-size:1em;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  flex-direction: column;
  padding:20px 10px;
  margin:30px 0;
  animation: fadeinMain-e-message 1.3s;
  @keyframes fadeinMain-e-message {
    from { opacity: 0; transform: translateY(100%)}
    to   { opacity: 1; transform: translateY(0%)}
  }
  h1 {
    font-weight: bold !important;
    position:relative;
  }

  p {
    padding:0em 0 2em;
    margin:auto;
  }

  &--ok {
    background-color:#008a00;
    color:#FFF;   
  }

  &--info {
    background-color:#465a97;
    color:#FFF;
  }
  &--error {
    background-color:#c6040e;
    color:#FFF;
  }
}