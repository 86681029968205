.c-slider-home {
  h1 {
    // font-family: "Great Vibes";
    color:#FFF;
    font-size: 5em;
    // font-weight:400;
  }
  h2 {
    font-size: 3em;
    line-height: 1.2em;
  }
  @include mobile-xs {
    h1 {
      font-size: 10em;

    }
    h2 {
      font-size: 6em;
    }
  }

  .item-desktop {
    @include mobile {
      opacity: 0 !important;
    }
  }

  .item-mobile {
    opacity:0 !important;
    position:relative;
    width: 100%;
    @include mobile {
      opacity:1 !important;
      img {
        position: absolute;
        z-index: 999;
        width:50%;
        right:0%;
        margin-top:10%;
        width: 50% !important;
      }
    }
    &--top-diferent {
      margin-top:-5%;
    }
  }

  .item-sostenible-mobile {
    opacity: 0 !important;
    @include mobile-xs {
      opacity:1 !important;
      img {
        position: absolute;
        z-index: 999;
        width:50%;
        right:25%;
        left:25%;
      }
    }
  }
  .item-sostenible {
    @include mobile-xs {
      opacity:0 !important;
    }
  }
}