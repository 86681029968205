.e-scroll-horizontal{
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  background-image: 
  /* Shadows */ 
  linear-gradient(to right, white, white),
  linear-gradient(to right, white, white),
  
  /* Shadow covers */ 
  linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
  linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0));   

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}