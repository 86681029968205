// GENERAL THEME EXTRAS
.e-filter-image-color-brown {
  filter: grayscale(100%) sepia(100) contrast(1) invert(0) saturate(0);
  // filter: none | blur() | brightness() | contrast() | drop-shadow() | grayscale() | hue-rotate() | invert() | opacity() | saturate() | sepia() | url();
  transition:.5S;
  &:hover {
    filter:initial
  }
}

.e-title {
  font-size:4rem;
  margin:0 0 0 0;
  padding:0;
  @include mobile {
    font-size:2.5rem;
  }
}

.e-no-radius {
  border-radius:0 !important;
}

.e-hr-theme {
  border:0;
  display:block;
  width:100%;
  height:1px;
  background-color:rgba(#EAEAEA,.6);
}

// Effect Oscure Banner element
.e-oscure-banner {
	position:relative;
	img {
		display: block;
	}
	&:before {
		content:("");
		background-color:rgba(#000,.25);
		width: 100%;
		height:100%;
		left:0;
		top:0;
		position: absolute;
		transition:1s;
	}
	&--opacity-50  {
		&:before {
			background-color:rgba(#000,.50);
		}
	}
}


// Order
.e-order {
	&-5 {
		order:5;
	}
}


// E-play-video
.e-play-video {
	a {
		display:block;
		&::after {
			content:("");
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background:url('assets/svg/boton-de-play.svg') no-repeat center center;
			background-size:50% 50%;
			z-index:2
		}
		img {
			display:block;
			filter: brightness(.5) invert(0) grayscale(0);
			transition:.5s;
		}
		&:hover {
			img {
				filter: brightness(1) invert(0) grayscale(0);
			}
		}
	}
}

// special font 
.e-font-adami {
	font-family: 'Adamina', serif;
}
.e-font-cabin {
  font-family: 'Cabin',sans-serif;
}

.e-bb-0 {
	border:0 !important;
}
.e-color-fill-white {
  svg path {
    fill:#FFF;
  }
}


// E-FILL
.e-fill-white {
	svg {
		fill:#FFF;
	}
}


.e-link-cool {
	border-bottom: 1px solid #CCC;
	display: inline-block;
	line-height: 0.85;
	text-shadow:
	2px 2px white,
	2px -2px white,
	-2px 2px white,
	-2px -2px white;
}


// E-ICO : ICONS by SVG
.e-ico {
	font-size: 0;
	svg {
		width:24px;
		height:24px;
	}
	&--facebook {
	}
	&--instagram {
	}
	&--phone {
		svg {
			width:14px;
			height:14px;
			margin-right: 4px;
			transform: translateY(2px);
		}
	}
}
.e-icon {
	display: inline-block;
	vertical-align: middle;
	margin-left:1em
}



