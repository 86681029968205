// E-BORDER
.e-border {
  &-0 {
    border:0 !important;
  }
}
.e-border {
  &-right {
    border-right:1px solid #dcdcdc;
  }
  &-bottom {
    border-bottom:1px solid #dcdcdc;;
  }
  &--no-mobile {
    &-0 {
      @include mobile {
        border:0;
      }
    }
    &-right {
      @include mobile {
        border-right:0;
      }
    }
    &-left {
      @include mobile {
        border-left:0;
      }
    }
    &-top {
      @include mobile {
        border-top:0;
      }
    }
    &-bottom {
      @include mobile {
        border-bottom:0;
      }

    }
  }
  &-only-mobile {
    &-0 {
      @include mobile {
        border:0;
      }
    }
    &-right {
      @include mobile {
        border-right:1px solid #dcdcdc;;
      }
    }
    &-left {
      @include mobile {
        border-left:1px solid #dcdcdc;;
      }
    }
    &-top {
      @include mobile {
        border-top:1px solid #dcdcdc;;
      }
    }
    &-bottom {
      @include mobile {
        border-bottom:1px solid #dcdcdc;;
      }
    }
  }
  &-dotted {
    &-bottom {
      border-bottom:1px dotted;
    }
  }
}