// e-displays BLOCKS : DISPLAY : FLEX
.e-display {
  &-block {
    display:block !important;
  }
  &-initial {
    display:initial !important;
  }
  &-inline-block {
    display:inline-block !important;
  }
}
.e-display-flex {
  @include flexbox();
  &--mobile {
    @include flexbox();
  }
}
.e-display-inline-block{
  display: inline-block !important;
}
.e-display-none {
  display:none !important;
  &--mobile {
    @include mobile {
      display:none !important;
    }
  }
}
.e-flex-wrap {
  @include flex-wrap(wrap);
}
.e-position-relative {
  position:relative;
}
.e-flex-direction 
{
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
}
.e-justify-content {
  &-center {
    justify-content: center;
    &--mobile {
      @include mobile {
        justify-content: center;
      }
    }
  }
  &-flex-end {
    justify-content: flex-end;
  }
  &-flex-start {
    justify-content: flex-start;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-space-around {
    justify-content: space-around;
  }
}
.e-flex-direction {
  &-column {
    flex-direction: column;
  }
}
.e-flex {
  &-1 {
    flex:1;
  }
}
.e-float {

  &-right {
    float:right;
    @include mobile {
      &--mobile {
        float:right !important
      }
    }
  }

  &-left {
    float:left;
    @include mobile {
      &--mobile {

        float:left !important
      }
    }
  }
  &-none {
    float:none;
    @include mobile {
      &--mobile {

        float:none !important
      }
    }
  }
}