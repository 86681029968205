// SUMMARY
.e-summary {
  details
  {
    transition:3s;
    min-height: 30px;
    border-bottom:1px solid #EFEFEF;
    line-height: 30px;
    margin-top:10px;
    padding:20px 0;
    transform:translateY(20px);
    border:0;
    outline:none;
    :focus {
      outline: 0;

    }
    &[open]{
      summary {
        &:before {
          content: "-"; /* the new icon */
          
        }
      }
    }
    summary {
      cursor:pointer;
      border-bottom:1px solid #111;
      display:flex;
      background:none;
      position:relative;
      &:hover {
        border-bottom:2px solid #111;
      }
      &::-webkit-details-marker {
        display: none;
      }
      &:before {
        content: "+"; /* the new icon */
        color: #111;
        margin-right: 5px;
        font-weight:bold;
        font-size: 45px;
        transform:translate(-24px , calc(50% - 50px));
        outline:0;
        position:absolute;
        right:50%;
        left:50%;
        border:4px solid #FFF;
        box-shadow:inset 0px 0px 1px 1px #111;
        width:40px;
        height:40px;
        line-height:40px;
        text-align:center;
        background:#FFF;
        font-weight:400;
      }
      
      h2 {
        vertical-align:middle;
        margin:0;
        padding:0;
        width:calc(100% - 45px);
        position:absolute;
        width:100%;
        text-align:center;
        top:1.5em;
      }
    }
    p {
      margin:0;
      padding:.5em 0;
      margin-bottom: .8em;
    }
  }
}
details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}
@keyframes sweep {
  0%    {opacity: 0; transform:translateY(5px);}
  100%  {opacity: 1; }
}