// @import url('https://fonts.googleapis.com/css?family=Cabin&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto+Slab:300,400,700')
// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');
@font-face {
  // font-family: BeautifulFriday;
  // src: url($path + '/fonts/Beautiful Friday 06.ttf');
}
// @import url('https://fonts.googleapis.com/css?family=Assistant:400,600');
// @import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');
// @import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Oswald:400,600&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,300;0,400;0,700;1,400&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:700,900');
// @import url('https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,300;0,400;0,700;1,400&family=Shadows+Into+Light&display=swap');

// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Cabin:400,600,700,400italic,500|Droid+Serif:400,700,400italic,700italic|Libre+Baskerville:400,400italic&subset=latin,latin-ext');
// @import url('https://fonts.googleapis.com/css?family=Cabin:600|Droid+Serif:400,700');
// @import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
// 
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Great+Vibes%7CLato:100,100i,300,300i,400,400i,700,700i,900,900i');

